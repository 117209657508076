import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import BlogCard from "./blog-card";

import translations from "../config/translations";
import LanguageContext from "../contexts/Language";

import {
  MAIN_PADDING,
  MAIN_PADDING_2,
  MAIN_COLOR,
  MAX_CONTENT_WIDTH,
  BREAKPOINTS,
} from "../constants";
import Link from "next/link";
import React from "react";

import isMobile from "../hooks/is-mobile";
import CustomLink from "./custom-link";

const BlogWrapper = styled.div`
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH};
  margin: ${MAIN_PADDING_2} auto;

  @media (max-width: ${BREAKPOINTS.MD}) {
    margin: ${MAIN_PADDING} auto;
  }
`;

const BlogHeader = styled.h3`
  color: ${MAIN_COLOR};
  padding: 0px ${MAIN_PADDING};
  margin: 0;
  font-size: 26px;
  font-weight: 200;
`;

const BlogCards = styled.div`
  padding: ${MAIN_PADDING};

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${MAIN_PADDING};
  grid-row-gap: ${MAIN_PADDING};
  grid-auto-rows: min-content;

  @media (max-width: ${BREAKPOINTS.MD}) {
    grid-template-columns: 1fr;
  }
`;

const Button = styled.button`
  background-color: #4b5068;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  text-align: center;
  margin-left: auto;
  margin-right: ${MAIN_PADDING};
  display: block;
`;

const BlogLink = styled.div`
  @media (max-width: ${BREAKPOINTS.MD}) {
    display: none;
  }
`;

export default function BlogBlock(props) {
  const lang = useContext(LanguageContext);
  const mobile = isMobile();
  const [nrOfCards, setNrOfCards] = useState(mobile);

  useEffect(() => {
    setNrOfCards(mobile ? 1 : 3);
  }, [mobile]);

  if (!props?.blog?.length) {
    return null;
  }

  if (props.isHomePage) {
    return (
      <BlogWrapper>
        <BlogHeader>{props.title || translations["Blog and News"][lang]}</BlogHeader>
        <BlogCards>
          {props?.blog?.length > 0 && props.blog?.slice(0, nrOfCards).map((item, index) => (
            <BlogCard key={index} item={item} />
          ))}
        </BlogCards>
        <BlogLink>
          <CustomLink href={`/blog`} locale={lang} passHref>
            <Button>{translations["See more"][lang]}</Button>
          </CustomLink>
        </BlogLink>
      </BlogWrapper>
    );
  }

  return (
    <BlogWrapper>
      <BlogHeader>{props.title || translations["Blog and News"][lang]}</BlogHeader>
      <BlogCards>
        {
          props.blog?.map((item, index) => (
            <BlogCard key={index} item={item} />
          ))}
      </BlogCards>
    </BlogWrapper>
  );
}
