import { useContext } from "react";
import styled from "styled-components";
import Link from "next/link";
import moment from "moment";

import translations from "../config/translations";
import LanguageContext from "../contexts/Language";

import { MAIN_COLOR, BREAKPOINTS } from "../constants";
import React from "react";

import CustomLink from "./custom-link";

const BlogImage = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  &:after {
    content: "";
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-image: url(${(props) => (props.bgImage ? props.bgImage : "")});
    transition: 0.3s ease all;
    z-index: 1;
  }

  &:hover {
    cursor: pointer;

    &:after {
      transform: scale(1.1);
    }
  }
`;

const BlogContent = styled.div`
  padding: 25px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 85px;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
`;

const BlogTitle = styled.a`
  font-weight: 600;
  line-height: 1.25em;
  width: 100%;
  color: ${MAIN_COLOR};
  font-size: 16px;

  &:hover {
    color: gray;
    cursor: pointer;
  }
`;

const BlogDescription = styled.p`
  width: 100%;
  line-height: 1.5em;

  @media (max-width: ${BREAKPOINTS.MD}) {
    display: none;
  }
`;

const BlogDate = styled.p`
  color: #b7b7b7;
`;

export default function BlogCard(props) {
  const lang = useContext(LanguageContext);

  function add3Dots(string, limit) {
    if (string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + "...";
    }

    return string;
  }
  
  const excerpt = add3Dots(
    props.item.content.rendered.replace(/<\/?[^>]+>/gi, " ").replace(/&#8211;/g, ''),
    200
  );

  return (
    <div>
      <CustomLink
        href={`/blog/[slug]`}
        as={`/blog/${props.item.slug}`}
        locale={lang}
        passHref
      >
        <BlogImage bgImage={props.item.featured_image_url} />
      </CustomLink>

      <BlogContent>
        <CustomLink
          href={`/blog/[slug]`}
          as={`/blog/${props.item.slug}`}
          locale={lang}
          passHref
        >
          <BlogTitle>{props.item.title.rendered}</BlogTitle>
        </CustomLink>

        <BlogDescription dangerouslySetInnerHTML={{ __html: excerpt }}>
        </BlogDescription>
        <BlogDate>{moment(props.item.date).format("DD.MM.YYYY")}</BlogDate>
      </BlogContent>
    </div>
  );
}
